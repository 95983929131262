const names = [
  'Müller',
  'Schmidt',
  'Schneider',
  'Fischer',
  'Weber',
  'Schäfer',
  'Meyer',
  'Wagner',
  'Becker',
  'Bauer',
  'Hoffmann',
  'Schulz',
  'Koch',
  'Richter',
  'Klein',
  'Wolf',
  'Schröder',
  'Neumann',
  'Braun',
  'Werner',
  'Schwarz',
  'Hofmann',
  'Zimmermann',
  'Schmitt',
  'Hartmann',
  'Schmid',
  'Weiß',
  'Schmitz',
  'Krüger',
  'Lange',
  'Meier',
  'Walter',
  'Köhler',
  'Maier',
  'Beck',
  'König',
  'Krause',
  'Schulze',
  'Huber',
  'Mayer',
  'Frank',
  'Lehmann',
  'Kaiser',
  'Fuchs',
  'Herrmann',
  'Lang',
  'Thomas',
  'Peters',
  'Stein',
  'Jung',
  'Möller',
  'Berger',
  'Martin',
  'Friedrich',
  'Scholz',
  'Keller',
  'Groß',
  'Hahn',
  'Roth',
  'Günther',
  'Vogel',
  'Schubert',
  'Winkler',
  'Schuster',
  'Lorenz',
  'Ludwig',
  'Baumann',
  'Heinrich',
  'Otto',
  'Simon',
  'Graf',
  'Kraus',
  'Krämer',
  'Böhm',
  'Schulte',
  'Albrecht',
  'Franke',
  'Winter',
  'Schumacher',
  'Vogt',
  'Haas',
  'Sommer',
  'Schreiber',
  'Engel',
  'Ziegler',
  'Dietrich',
  'Brandt',
  'Seidel',
  'Kuhn',
  'Busch',
  'Horn',
  'Arnold',
  'Kühn',
  'Bergmann',
  'Pohl',
  'Pfeiffer',
  'Wolff',
  'Voigt',
  'Sauer'
]
export default () => {
  const wordOne = names[Math.floor(Math.random() * names.length)]
  const wordTwo = names[Math.floor(Math.random() * names.length)]
  return `${wordOne} ${wordTwo}`
}
