<template>
  <v-dialog
    :value="true"
    persistent
    max-width="30rem"
    transition="dialog-transition"
  >
    <v-card>
      <template v-if="pairing">
        <v-card-title class="justify-center">
          {{ !success ? 'Pairing display... Please wait.' : success }}
        </v-card-title>
        <v-card-title class="justify-center pb-3">
          <v-progress-circular size="36" indeterminate />
        </v-card-title>
      </template>
      <template v-if="error">
        <v-card-title class="justify-center">
          Could not pair display.
        </v-card-title>
        <v-card-text v-if="error">
          <v-alert
            text
            type="error"
          >
            {{ error }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined color="primary" to="/displays">
            Close
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import getRandomMachineName from '@/utils/get_random_machine_name'
export default {
  name: 'Pair',
  data: () => ({
    pairing: true,
    success: '',
    error: ''
  }),
  async mounted() {
    const { pairingNumber } = this.$route.params
    const doc = await this.$db.collection('pairing-numbers').doc(pairingNumber).get()
    if (doc.exists) {
      const { machineId, resolution } = doc.data()
      const user = await this.$getAsyncCurrentUser()
      await this.$db.collection('displays').doc(machineId).set({
        name: `${getRandomMachineName()} Display`,
        createdAt: this.$nowstamp(),
        updatedAt: this.$nowstamp(),
        userId: user.uid,
        organizationId: this.$store.getters.organization.id,
        resolution
      })
      this.success = 'Completing pairing...'
      const { docs } = await this.$db.collection('pairing-numbers').where('machineId', '==', machineId).get()
      await Promise.all(docs.map((x) => {
        return this.$db.collection('pairing-numbers').doc(x.id).delete()
      }))
      this.$router.replace({ name: 'display', params: { id: machineId } })
    } else {
      this.error = 'Invalid number'
      this.pairing = false
    }
  }
}
</script>
